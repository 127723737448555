import { Pipe, PipeTransform } from '@angular/core';

import { formatDate } from '../utils/date-time';
import { ParseDateTimeInput } from '../utils/parse-date-time';

@Pipe({ name: 'formatDate', standalone: true })
export class FormatDatePipe implements PipeTransform {
  transform(value: ParseDateTimeInput): string {
    return formatDate(value);
  }
}
